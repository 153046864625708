<template>
    <div class="my-6 text-start">
        <h3 class="font-bold">Editar agendamento de vidada de Lote</h3>
        <span class="text-sm text-gray-500">Preencha as informações abaixo e clique no botão <b>criar</b> para
            salvar as alterações.
        </span>
    </div>
    <div class="flex flex-row items-center">
        <div class="w-full p-2">
            <Input type="datalist" id="evento" v-model="eventoId" label="Evento" placeholder="Selecione um evento"
                :data="eventos" @keyupSearch="getEvent" />
        </div>
        <div class="justify-center">
            <Button color="primary" :text="`Buscar`" @click="getProdutos" />
        </div>
    </div>
    <hr class="my-9" />
    <div class="card shadow-sm border p-8 bg-white">

        <div class="flex lg:flex-row flex-col items-center">
            <div class="w-full p-2">
                <Input type="default" id="detalhes" label="Detalhes" placeholder="Detalhes" v-model="detalhes" />
            </div>
            <div class="w-full p-2">
                <Input v-model="dia" type="date" id="data" label="Data" :minDate="today" />
            </div>

        </div>
        <div class="flex lg:flex-row flex-col items-center">
            <div class="w-full p-2">
                <Input v-model="hora" id="hora" type="customTime" label="Hora da troca de lote"
                    placeholder="Hora da troca de lote" />
            </div>

            <div class="w-full p-2">
                <Input v-model="status" id="status" type="select" label="Status" :data="[
                    {
                        label: 'Cancelado',
                        value: 0
                    },
                    {
                        label: 'Agendado',
                        value: 1
                    },
                    {
                        label: 'Concluido',
                        value: 2
                    },
                ]" />
            </div>
        </div>

        <div class="flex lg:flex-row flex-col items-center">
            <div class="w-full p-2" v-if="type != 2">
                <Input type="datalist" id="produtoDesativado" v-model="desativarId" label="Desativar Produto"
                    placeholder="Selecione o produto para Desativar" :data="produtos" />
            </div>

            <div class="w-full p-2" v-if="type == 1 || type == 2">
                <Input type="datalist" id="produtoAtivado" v-model="ativarId" label="Ativar Produto"
                    placeholder="Selecione o produto para Ativar" :data="produtos" />
            </div>
        </div>
        <div class="flex justify-end my-2">
            <Button @click="addTrocas()" color="primary" text="Adicionar"></Button>
        </div>

        <hr>
        <div class="card shadow-sm border p-8 bg-white">
            <div v-for="(item, index) in trocas" :key="index" class="flex lg:flex-row flex-col items-center">

                <div :class="`flex lg:flex-row flex-col items-center p-2 ${type == 1 ? 'gap-5' : ' w-full'
                    }`">
                    <ProdutoLote :produtoDetalhes="item.desativa.detalhes" :produtoValor="item.desativa.valor"
                        :produtoSKU="item.desativa.cod_produto " :produtoQuantidade="item.desativa.qnt_por_produto"
                        v-if="type != 2" />

                    <fa :icon="`fa-solid fa-circle-arrow-right`" size="xl" v-if="type == 1" />

                    <ProdutoLote :produtoDetalhes="item.ativa.detalhes" :produtoValor="item.ativa.valor"
                        :produtoSKU="item.ativa.cod_produto" :produtoQuantidade="item.ativa.qnt_por_produto"
                        v-if="type == 1 || type == 2" />
                </div>

                <fa :icon="`fa-solid fa-trash`" size="xl" @click="removeLote(index, item.id)" />
            </div>
        </div>
    </div>
    <div class="flex justify-end my-2">
                <Button @click="editar()" color="primary" text="Salvar"></Button>
    </div>
</template>

<script>
import Input from "../../components/Elements/Input.vue";
import { convertToUTC,toDateUS } from "@/services/formater";
import ProdutoLote from '../../components/Elements/ProdutoLote.vue'
import { inject, onMounted, ref } from "vue";
import Button from "../../components/Elements/Button.vue";
import { toDate } from "../../services/formater"
import { GET, PUT, POST } from "../../services/api";
import { useRoute, useRouter } from "vue-router";
import moment from 'moment';


export default {
    name: "loteEditar",
    components: {
        Input,
        Button,
        ProdutoLote
    },

    setup() {
        const route = useRoute();

        const router = useRouter();

        const eventos = ref([])
        const alert = inject("alert");
        const modal = inject("modal");
        const loader = inject("loading");
        const produtos = ref([]);
        const Resprodutos = ref([]);
        const eventoId = ref("");
        const desativarId = ref("");
        const ativarId = ref("");
        const detalhes = ref("")
        const dia = ref("")
        const hora = ref("")
        const status = ref("")
        const type = ref("")
        const idTrocaLote = ref("")

        const trocas = ref([])
        const today =moment(new Date()).format("YYYY-MM-DD");


        const getProdutos = async () => {
            loader.open();

            try {
                const [eventoSelecionado] = eventos.value.filter(
                    (evento) => evento.label == eventoId.value
                );

                eventoId.value = eventoSelecionado.value;

                Resprodutos.value = await GET(`produto/${eventoId.value}/evento`);



                Resprodutos.value.map(prod => {
                    produtos.value.push({ label: prod.cod_produto, value: prod.id })
                })

                if (produtos.value.length <= 0)
                    alert.open("Atenção!", `Não há produtos relacionados a esse evento!`, "warning");

                eventoId.value = eventoSelecionado.label;


            } catch (e) {

                alert.open("Erro", "Um erro ocorreu, tente novamente", "danger");

            }
            loader.close();
        }

        onMounted(async () => {
            try {
                loader.open();
                const response = await GET("event");


                const responseLote = await GET("/agendamento_lote/" + route.params.id);

                const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))

                detalhes.value = responseLote.data[0].identificacao
                idTrocaLote.value = responseLote.data[0].id
                dia.value = moment.tz(responseLote.data[0]['data_hora'], usuarioConta.timeZone).format('YYYY-MM-DD')
                hora.value = moment.tz(responseLote.data[0]['data_hora'], usuarioConta.timeZone).format('HH:mm')
                status.value = responseLote.data[0].status
                type.value = responseLote.data[0]['tipo_agendamento']

                responseLote.data[0].agendamentoProduto.map((elemento) => {
                    trocas.value.push({
                        id: elemento.id,
                        desativa: elemento.produtoDesativado,
                        ativa: elemento.produtoAtivado
                    })
                })


                response.map(evento => {
                    if (responseLote.data[0].agendamentoProduto[0].produtoDesativado.id_evento === evento.id
                        || responseLote.data[0].agendamentoProduto[0].produtoAtivado.id_evento === evento.id) {
                        eventos.value.push({ label: evento.nome + " - " + toDate(evento.data), value: evento.id })
                    }
                }
                );


                eventoId.value = eventos.value[0].label

                await getProdutos();
                loader.close()
            } catch (error) {
                loader.close()
                alert.open("Error", "Não foi possivel recuperar os eventos", "danger")
            }
        })



        const removeLote = async (index, itemId) => {

            modal.open("Atenção",
                "Você tem certeza em remover essa troca." +
                "Deseja continuar?",
                "warning",
                "Sim",
                async () => {
                    loader.open();
                    try {

                        loader.open();
                        await POST(`agendamento_produto/delete/${itemId}`)

                        trocas.value.splice(index, 1)
                        alert.open(
                            "Sucesso!",
                            `Carga realizada com sucesso!`,
                            "success"
                        );
                        //remover os subtiopos selecionados apos dar carga
                        loader.close();
                    } catch (error) {
                        loader.close();
                        alert.open("Atenção!", error, "danger");
                    }
                });

        }

        const addTrocas = async () => {
            try {
                loader.open();
                if (type.value == 1) {
                    const [PDesativado] = produtos.value.filter(
                        (prod) => prod.label == desativarId.value
                    );

                    const [PAtivar] = produtos.value.filter(
                        (prod) => prod.label == ativarId.value
                    );

                    const [ProdutoDesativado] = Resprodutos.value.filter((prod) => prod.id === PDesativado.value)
                    const [ProdutoAtivado] = Resprodutos.value.filter((prod) => prod.id === PAtivar.value)

                    const obj = {
                        produtoDesativado: ProdutoDesativado.id,
                        produtoAtivado: ProdutoAtivado.id,
                        "id_agendamento_lote": idTrocaLote.value
                    }

                    const response = await POST('agendamento_produto/create', obj)


                    trocas.value.push({
                        desativa: ProdutoDesativado,
                        ativa: ProdutoAtivado
                    })

                } else if (type.value == 2) {
                    const [PAtivar] = produtos.value.filter(
                        (prod) => prod.label == ativarId.value
                    );

                    const [ProdutoDesativado] = Resprodutos.value.filter((prod) => prod.id === PAtivar.value)
                    const [ProdutoAtivado] = Resprodutos.value.filter((prod) => prod.id === PAtivar.value)

                    const obj = {
                        produtoDesativado: ProdutoDesativado.id,
                        produtoAtivado: ProdutoAtivado.id,
                        "id_agendamento_lote": idTrocaLote.value
                    }

                    const response = await POST('agendamento_produto/create', obj)


                    trocas.value.push({
                        desativa: ProdutoDesativado,
                        ativa: ProdutoAtivado
                    })

                } else if (type.value == 3 || type.value == 4) {

                    const [PDesativado] = produtos.value.filter(
                        (prod) => prod.label == desativarId.value
                    );

                    const [ProdutoDesativado] = Resprodutos.value.filter((prod) => prod.id === PDesativado.value)
                    const [ProdutoAtivado] = Resprodutos.value.filter((prod) => prod.id === PDesativado.value)

                    const obj = {
                        produtoDesativado: ProdutoDesativado.id,
                        produtoAtivado: ProdutoAtivado.id,
                        "id_agendamento_lote": idTrocaLote.value
                    }

                    const response = await POST('agendamento_produto/create', obj)


                    trocas.value.push({
                        desativa: ProdutoDesativado,
                        ativa: ProdutoAtivado
                    })
                }
                loader.close();

            } catch (error) {
                loader.close();
                alert.open("Error", error ? error : "um erro ocorreu, tente novamente", "danger")
            }
        }

        const editar = async () => {
            const usuarioConta = JSON.parse(localStorage.getItem('info_santo_cartao_adm'))

            const correctDate = dia.value
                .replace("-", "/")
                .replace("-", "/")
                .split("/")
                .reverse()
                .join("/");


            const body = {
                identificacao: detalhes.value,
                data: convertToUTC(correctDate, hora.value, usuarioConta.timeZone),
                status: status.value,

            }

            if (body.data === "" || body.identificacao === "") {
                alert.open("Atenção!", 'Campos faltando, confira novamente', "warning");
            } else {
                try {
                    loader.open();
                    await PUT('/agendamento_lote/' + route.params.id, body)
                    loader.close()

                    alert.open("Sucesso!", `Agendamento editado com sucesso!`, "success");
                    router.push(`/lotes`);


                } catch (error) {
                    loader.close()
                    alert.open("Error", "Não foi possivel editar o Agendamento", "danger")
                }
            }



        }

        return {
            trocas,
            hora,
            dia,
            detalhes,
            eventos,
            getProdutos,
            eventoId,
            produtos,
            desativarId,
            ativarId,
            addTrocas,
            removeLote,
            editar,
            status,
            type,
            today,
            toDateUS

        }
    }
}
</script>

<style></style>
